<template>
  <div>

    <!-- Filter Section -->
    <b-card title="Filters" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
          <!-- Period -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.reportPurchaseAging.singular.period')"
              label-for="period"
            >
              <v-select
                input-id="period"
                class="select-size-sm"
                v-model="form.period"
                :options="LOV.period"
                :reduce="field => field.value"
                @input="handlePeriod"
              />
            </b-form-group>
          </b-col>

          <!-- Until Start -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.reportPurchaseAging.singular.dateUntil')"
              label-for="date-until"
            >
              <flat-pickr
                id="date-until"
                :config="dateFormat"
                v-model="form.dateUntil"
                class="form-control form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Contact -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('apps.reportPurchaseAging.singular.contact')"
              label-for="contact"
            >
              <v-select
                id="contacts"
                class="select-size-sm"
                v-model="form.contactId"
                :options="LOV.contacts"
                :reduce="field => field.id"
                label="label"
              />
            </b-form-group>
          </b-col>

          <!-- Agent (New Field) -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('globalSingular.agent')"
              label-for="agent"
            >
              <v-select
                id="agent"
                class="select-size-sm"
                v-model="form.agentId"
                :options="LOV.agentLov"
                :reduce="field => field.id"
                label="label"
              />
            </b-form-group>
          </b-col>

          <!-- Tagging -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('apps.reportPurchaseAging.singular.tags')"
              label-for="tags"
            >
              <v-select
                id="tags"
                class="select-size-sm"
                label="name"
                v-model="form.tags"
                :options="LOV.tags"
                :reduce="field => field.id"
                multiple
              />
            </b-form-group>
          </b-col>

          <!-- Button Filter -->
          <b-col cols="12" md="2">
            <b-button block variant="primary" size="sm" @click="getReport" class="mt-2 pd-1">
              {{ $t('globalActions.find') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <div class="d-flex justify-content-end mt-1 mb-1">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">

        <b-card-body class="text-center">
          <b-card-title>{{ companyName }}</b-card-title>
          <b-card-sub-title>
            <div>{{ $t('apps.reportPurchaseAgingDetail.singular.purchaseAgingDetailReport') }}</div>
            <div class="mt-sm-1">{{ periodReportText }}</div>
          </b-card-sub-title>
        </b-card-body>

      <b-table
        id="refListTable"
        ref="refListTable"
        no-provider-paging
        no-provider-filtering
        :filter-included-fields="columnToBeFilter"
        :fields="tableColumns"
        :items="itemLists"
        :filter="searchQuery"
        responsive
        primary-key="id"
        show-empty
        :busy="isBusy"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        small
      >

        <template #table-busy>
          <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
            <div class="text mb-1">
              <b-spinner class="align-middle"/>
            </div>
            <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
          </div>
        </template>

        <template #thead-top>
          <b-tr>
            <b-th colspan="7"></b-th>
            <b-th colspan="6" class="text-center">Debt Age (Days)</b-th>
          </b-tr>
        </template>

        <template #cell(sum_of_bill_amount)="{ item }">
          <span class="d-block text-nowrap text-right">
            {{ formatCurrency(item.sum_of_bill_amount) }}
          </span>
        </template>

        <template #cell(outstanding_amount)="{ item }">
          <span class="d-block text-nowrap text-right">
            {{ formatCurrency(item.outstanding_amount) }}
          </span>
        </template>

        <template #cell(not_yet_due)="{ item }">
          <span class="d-block text-nowrap text-right">
            {{ formatCurrency(item.not_yet_due) }}
          </span>
        </template>

        <template #cell(due15)="{ item }">
          <span class="d-block text-nowrap text-right">
            {{ formatCurrency(item.due15) }}
          </span>
        </template>

        <template #cell(due30)="{ item }">
          <span class="d-block text-nowrap text-right">
            {{ formatCurrency(item.due30) }}
          </span>
        </template>

        <template #cell(due60)="{ item }">
          <span class="d-block text-nowrap text-right">
            {{ formatCurrency(item.due60) }}
          </span>
        </template>

        <template #cell(due90)="{ item }">
          <span class="d-block text-nowrap text-right">
            {{ formatCurrency(item.due90) }}
          </span>
        </template>

        <template #cell(due120)="{ item }">
          <span class="d-block text-nowrap text-right">
            {{ formatCurrency(item.due120) }}
          </span>
        </template>

        <template #cell(due120_plus)="{ item }">
          <span class="d-block text-nowrap text-right">
            {{ formatCurrency(item.due120_plus) }}
          </span>
        </template>

      </b-table>

    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItemButton,
  BTable,
  BTr,
  BTh,
  BSpinner
} from 'bootstrap-vue'

import { format, getMonth, endOfMonth, subMonths, addMonths, subYears, parse } from 'date-fns'
import { ref, onMounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import { formatCurrency, unformatNumber } from '@/utils/formatter'
import flatPickr from 'vue-flatpickr-component'
import useListTable from '@/comp-functions/useListTable'
import router from '@/router'

import useFormResource from '@/comp-functions/useFormResource'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    BTable,
    BTr,
    BTh,
    BSpinner,
    vSelect,
    flatPickr
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const { get } = useFormResource({ url: false })
    const user = store.state.user

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const form = ref({
      contactId: '',
      agentId: '',
      tags: [],
      period: 'thisMonth',
      dateUntil: ''
    })

    const periodReportText = ref()

    // export format
    const formats = ['xlsx', 'csv', 'txt']

    const dateFormat = ref({ dateFormat: 'd/m/Y', allowInput: true })

    const LOV = ref({
      period: [
        { value: 'thisMonth', label: 'This Month' },
        { value: 'previousMonth', label: 'Previous Month' },
        { value: 'thisYear', label: 'This Year' },
        { value: 'previousYear', label: 'Previous Year' }
      ],
      contacts: [],
      agentLov: [],
      tags: []
    })

    // Table Handlers
    const tableColumns = [
      { key: 'code', label: 'Invoice No' },
      { key: 'supplier', label: 'Vendor' },
      { key: 'date', label: 'Date' },
      { key: 'due_date', label: 'Due Date' },
      { key: 'sum_of_bill_amount', label: 'Bill Amount' },
      { key: 'outstanding_amount', label: 'Outstanding' },
      { key: 'not_yet_due', label: 'Not Yet Due' },
      { key: 'due15', label: '1-15' },
      { key: 'due30', label: '16-30' },
      { key: 'due60', label: '31-60' },
      { key: 'due90', label: '61-90' },
      { key: 'due120', label: '91-120' },
      { key: 'due120_plus', label: '> 120' }
    ]

    // Table Handlers
    const columnToBeFilter = ['code']

    const getContacts = async () => {
      LOV.value.contacts = await get({ url: 'value/contact/supplier'})
    }

    const getAgent = async () => {
      LOV.value.agentLov = await get({ url: 'value/contact/agent'})
      if (!user.isNational) {
        form.value.agentId = LOV.value.agentLov[0].id
      }
    }

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const handlePeriod = () => {
      const formatString = 'dd/MM/yyyy'
      const thisMonth = new Date()
      const whatMonth = getMonth(thisMonth) + 1
      const previousMonth = subMonths(thisMonth, 1)
      const lastMonthOfYear = addMonths(thisMonth, (whatMonth - 12) * -1)
      const lastMonthOfPreviousYear = subYears(lastMonthOfYear, 1)

      if (form.value.period === 'thisMonth') {
        form.value.dateUntil = format(endOfMonth(thisMonth), formatString)
      } else if (form.value.period === 'previousMonth') {
        form.value.dateUntil = format(endOfMonth(previousMonth), formatString)
      } else if (form.value.period === 'thisYear') {
        form.value.dateUntil = format(endOfMonth(lastMonthOfYear), formatString)
      } else {
        form.value.dateUntil = format(endOfMonth(lastMonthOfPreviousYear), formatString)
      }
    }

    const updatePeriodText = () => {
      const dateUntil = parse(form.value.dateUntil, 'dd/MM/yyyy', new Date())
      const formatUntilDate = format(dateUntil, 'd MMMM yyyy')
      periodReportText.value = `${formatUntilDate}`
    }

    const getReport = async () => {
      const params = [
        `dateUntil=${form.value.dateUntil}`,
        `contactId=${form.value.contactId ?? ''}`,
        `agentId=${form.value.agentId ?? ''}`,
        `tags={${form.value.tags}}`
      ]

      const url = `reports/purchase/aging-detail?${ params.join('&') }`
      await fetchLists(url)
    }

    const setParams = async (params) => {
      form.value.contactId = params.contactId
      form.value.dateUntil = params.dateUntil
    }

    onMounted(async () => {
      isBusy.value = true
      handlePeriod()
      if (router.currentRoute.query.contactId) {
        await setParams(router.currentRoute.query)
      }

      await getContacts()
      await getAgent()
      await getTags()
      await getReport()
      updatePeriodText()
    })

    return {
      formatCurrency,
      form,
      periodReportText,
      formats,
      LOV,
      dateFormat,
      tableColumns,
      columnToBeFilter,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      handlePeriod,
      updatePeriodText,
      getReport
    }
  },
  methods: {
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list).map((row) => {
          return row.map((cell) => {
            return unformatNumber(cell);
          })
        });
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', '', '', '', '', '', '', ''],
            [`${this.$t('apps.reportPurchaseAgingDetail.singular.purchaseAgingDetailReport')}`, '', '', '', '', '', '', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', '', '', '', '', '', '', ''],
            ['', '', '', '', '', '', '', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 12, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 12, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 12, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 12, r: 3 } }
          ],
          filename: `${this.$t('apps.reportPurchaseAgingDetail.singular.purchaseAgingDetailReport')} ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  #pdfDom td, #pdfDom th {
    padding: 0.6rem;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }

      #pdfDom td, #pdfDom th {
        padding: 0.72rem;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
